import {useTranslation} from "next-i18next";
import Img from "./image";
import React from "react";
import Link from "next/link";
import Util from "../lib/util";

/**
 *
 * @constructor
 */
export default function Explanation () {
    // init
    const { t } = useTranslation();
    const util = new Util();

    // render
    return (
        <>
            <div className="row row-cols-1 row-cols-md-3">
                <div className="col mb-5">
                    <div className="card h-100 bg-light border-0 text-center">
                        <div className="card-body">
                            <Img url={'/content/score-advantage.png'} alt={t('search.or.scan')} width={1920} height={768} priority={true} />
                            <h2 className="card-title mt-2">{'1. ' + t('search.or.scan')}</h2>
                            <p className="card-text">
                                <Link href={'/product/search'}>{util.capitalizeFirstLetter(t('search.verb'))}</Link>
                                <span> {t('explanation.search.or.scan.lead.part.one', { ns: 'glossary' , search: ''})} </span>
                                <Link href={'/product/scan'}>{t('scan.verb')}</Link>
                                <span> {t('explanation.search.or.scan.lead.part.two', { ns: 'glossary', scan: '' })}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col mb-5">
                    <div className="card h-100 bg-light border-0 text-center">
                        <div className="card-body">
                            <Img url={'/content/score.png'} alt={t('check.score')} width={1920} height={768} priority={false} />
                            <h2 className="card-title mt-2">{'2. ' + t('check.score')}</h2>
                            <p className="card-text">
                                <span>{t('our')} </span>
                                <Link href={'/score'}>{t('score.between')}</Link>
                                <span> {t('score.lead', { ns: 'glossary', ourScore: ''})}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col mb-5">
                    <div className="card h-100 bg-light border-0 text-center">
                        <div className="card-body">
                            <Img url={'/content/search.png'} alt={t('healthier.alternatives')} width={1920} height={768} priority={false} />
                            <h2 className="card-title mt-2">{'3. ' + t('healthier.alternatives')}</h2>
                            <p className="card-text">{t('explanation.healthier.alternatives.lead', { ns: 'glossary' })}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}