import React from "react";
import Base from "./base";

/**
 *
 * @returns {string}
 */
function createStructuredData()
{
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": process.env.appName,
        "description": process.env.description,
        "url": process.env.url,
        "image": process.env.logoURL,
        "logo": process.env.logoURL,
        "sameAs": [
            process.env.youtube.url,
            process.env.facebook.url,
            process.env.instagram.url,
            process.env.twitter.url
        ],
        "address": {
            "@type": "PostalAddress",
            "addressRegion": process.env.address.region,
            "postalCode": process.env.address.postalCode,
            "addressCountry": process.env.address.country,
            "addressLocality": process.env.address.locality,
            "streetAddress": process.env.address.street
        },
        "email": process.env.email,
        "telephone": process.env.phone
    };

    return JSON.stringify(structuredData);
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Organization () {
    // render
    return (
        <>
            {/* Structured-Data */}
            <Base
                id={'organization'}
                content={
                    createStructuredData()
                }
            />
        </>
    );
}