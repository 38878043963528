import React from "react";
import Base from "./base";

/**
 *
 * @returns {string}
 */
function createStructuredData()
{
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": process.env.url,
        "potentialAction": {
            "@type": "SearchAction",
            "target": {
                "@type": "EntryPoint",
                "urlTemplate": process.env.url + "/product/search?query={search_term_string}"
            },
            "query-input": "required name=search_term_string"
        }
    };

    return JSON.stringify(structuredData);
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Search () {
    // render
    return (
        <>
            {/* Structured-Data */}
            <Base
                id={'searchbox'}
                content={
                    createStructuredData()
                }
            />
        </>
    );
}