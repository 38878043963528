import MetaData, {Type} from "../components/metadata";
import React from "react";
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import Footer from "../components/footer";
import Header from "../components/header";
import Organization from "../components/structuredData/organization";
import Search from "../components/structuredData/search";
import Page from "../lib/page";
import Template from "../lib/template";
import PageRenderer from "../components/page";
import Explanation from "../components/explanation";
import Form from "../components/search/form";
import Scanner from "../components/barcode/scanner";
import Link from "next/link";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Index(props) {
    // init
    const { t } = useTranslation();

    // render
    return (
        <>
            {/* Metadata */}
            <MetaData
                type={Type.Website}
                title={t('how.healthy.is', { ns: 'glossary' })}
                description={t('search.lead', { ns: 'glossary' })}
                keywords={[t('homepage')]}
                imageURL={process.env.logoURL}
            />

            {/* Structured-Data */}
            <Organization />
            <Search />

            {/* Header */}
            <Header />

            {/* Title */}
            <h1 className="text-center fw-bold ms-3 me-3 ms-lg-4 me-lg-4 ps-lg-5 pe-lg-5 mt-5">{t('how.healthy.is', { ns: 'glossary' })}</h1>
            <p className={'lead text-center ms-3 me-3 ms-lg-4 me-lg-4 ps-lg-5 pe-lg-5'}>{t('search.lead', { ns: 'glossary' })}</p>

            {/* Explanation */}
            <div className={'ms-3 me-3 ms-lg-4 me-lg-4 ps-lg-5 pe-lg-5 mt-5 mb-lg-5'}>
                <Explanation />
            </div>

            {/* Search & Scan */}
            <div className={'row ms-3 me-3 ms-md-4 me-md-4 ps-lg-5 pe-lg-5 mb-5 pb-3'}>
                <hr style={ {opacity: 1} } className={'border border-2 border-primary mt-3 mb-4 mt-lg-0 mb-lg-5'} />

                {/* Search */}
                <div className={'col-lg-6 container mt-4 mt-lg-0'}>
                    <div className={'text-center'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#105028"
                             className="bi bi-search" viewBox="0 0 16 16">
                            <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                        </svg>
                    </div>
                    <h2 className="row justify-content-center mb-4 mt-3">{t('search.product')}</h2>
                    <Form type={'product'} />
                </div>

                {/* Scanner */}
                <div className={'col-lg-6 container mt-5 mt-lg-0 pt-4 pt-lg-0 mb-4 mb-lg-0'}>
                    <div className={'text-center'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#105028"
                             className="bi bi-upc-scan" viewBox="0 0 16 16">
                            <path
                                d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
                        </svg>
                    </div>
                    <h2 className="row justify-content-center mb-4 mt-3">{t('scan.barcode')}</h2>
                    <div className={'row justify-content-center'}>
                        <Scanner modal={true} />
                        <div className={'row justify-content-center'}>
                            <Link href={'/product/scan'} className={'mt-4 text-center w-auto'} >
                                {t('last.scanned.products', { ns: 'glossary' })}
                            </Link>
                        </div>
                    </div>
                </div>

                <hr style={ {opacity: 1} } className={'border border-2 border-primary mt-4 mt-lg-5'} />
            </div>

            {/* News */}
            <div className={'mt-5 pt-2 pt-lg-4 ms-3 me-3 ms-md-4 me-md-4 ps-lg-5 pe-lg-5 mb-5'}>
                <PageRenderer
                    type={props.news.template.type}
                    date={props.news.template.date}
                    data={props.news.template.data}
                    embed={true}
                />
                {props.news.template.data.pagination.moreResults
                    ?
                    <div className={'text-center mt-1'}>
                        <Link href={'/' + props.news.template.data.namespace} className={'text-center btn btn-primary'}>
                            {t('more.news', {ns: 'glossary'})}
                        </Link>
                    </div>
                    : ''
                }
            </div>

            {/* Blog */}
            {/*<div className={'mt-5 pt-2 pt-lg-4 ms-3 me-3 ms-md-4 me-md-4 ps-lg-5 pe-lg-5 mb-5'}>
                <PageRenderer
                    type={props.blog.template.type}
                    date={props.blog.template.date}
                    data={props.blog.template.data}
                    embed={true}
                />
                {props.blog.template.data.pagination.moreResults
                    ?
                    <div className={'text-center mt-1'}>
                        <Link href={'/' + props.blog.template.data.namespace} className={'text-center btn btn-primary'}>
                            {t('more.posts', {ns: 'glossary'})}
                        </Link>
                    </div>
                    : ''
                }
            </div>*/}

            {/* Footer */}
            <Footer />
        </>
    )
}

/**
 *
 * @param context
 * @returns {Promise<{props: {}}>}
 */
export async function getStaticProps(context) {
    let page = new Page;
    page.init(context.locale);

    let newsTemplate = new Template;
    await newsTemplate.init(
        page.language,
        'blog/news'
    );
    if(newsTemplate.found) {
        await newsTemplate.enrichData(context.locale);
        newsTemplate.data.pagination.entriesPerPage = 3;
        newsTemplate.data.pagination.moreResults = newsTemplate.data.pagination.resultCount > newsTemplate.data.pagination.entriesPerPage;
        newsTemplate.data.posts.length = Math.min(
            newsTemplate.data.pagination.resultCount,
            newsTemplate.data.pagination.entriesPerPage
        );
    }

    let blogTemplate = new Template;
    await blogTemplate.init(
        page.language,
        'blog'
    );
    if(blogTemplate.found) {
        await blogTemplate.enrichData(context.locale);
        blogTemplate.data.pagination.entriesPerPage = 3;
        blogTemplate.data.pagination.moreResults = blogTemplate.data.pagination.resultCount > blogTemplate.data.pagination.entriesPerPage;
        blogTemplate.data.posts.length = Math.min(
            blogTemplate.data.pagination.resultCount,
            blogTemplate.data.pagination.entriesPerPage
        );
    }

    return {
        props: {
            ...(await serverSideTranslations(context.locale, ['common', 'languages', 'countries', 'glossary'])),
            "news": {
                "template": {
                    "type": newsTemplate.type,
                    "date": newsTemplate.date,
                    "data": newsTemplate.data
                }
            },
            "blog": {
                "template": {
                    "type": blogTemplate.type,
                    "date": blogTemplate.date,
                    "data": blogTemplate.data
                }
            }
        }
    };
}